let message = document.querySelector("#startMessage"),
  field = document.querySelector("#inputField"),
  button = document.querySelector("#submitButton"),
  portal = document.querySelector("#hiddenPortal"),
  input = document.querySelector("#userInput"),
  suggestion = document.querySelector("#nameSuggestion"),
  content = document.querySelector("#secretContent");

button.addEventListener("click", function () {
  if (field.value === "Laird Christian Scott Trenk") {
    enterPortal();
  } else if (field.value !== "") {
    input.innerHTML = field.value;
    portal.classList.remove("invisible");
  } else {
    //do nothing
  }
});

suggestion.addEventListener("click", function () {
  enterPortal();
});

function enterPortal() {
  field.value = "Laird Christian Scott Trenk";
  field.disabled = true;
  message.classList.add("hidden");
  button.classList.add("hidden");
  portal.classList.add("hidden");
  content.classList.remove("hidden");
}
